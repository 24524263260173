export const nav = [
  {
    text: "homepage",
    path: "/",
  },
  {
    text: "interiors & features",
    path: "/interiors-features",
  },
  {
    text: "floorplans",
    path: "/floorplans",
  },
  {
    text: "location & lifestyle",
    path: "/location-lifestyle",
  },
  {
    text: "gallery",
    path: "/gallery",
  },
  {
    text: "team",
    path: "/team",
  },
];

export const hero = [
  {
    path: "../images/hero/home-bnr.jpg",
    alt: "Home Banner",
  },
  {
    path: "../images/hero/residences-bnr.jpg",
    alt: "Residences Banner",
  },
  {
    path: "../images/hero/floorplan-bnr.jpg",
    alt: "Floorplan Banner",
  },
  {
    path: "../images/hero/lifestyle-bnr.jpg",
    alt: "Lifestyle Banner",
  },
  {
    path: "../images/hero/gallery-bnr.jpg",
    alt: "Gallery Banner",
  },
  {
    path: "../images/hero/team-bnr.jpg",
    alt: "Team Banner",
  },
];
export const heroM = [
  {
    path: "../images/hero/home-bnr.jpg",
    alt: "Home Banner",
  },
  {
    path: "../images/hero/residences-bnr-1.jpg",
    alt: "Residences Banner",
  },
  {
    path: "../images/hero/floorplan-bnr.jpg",
    alt: "Floorplan Banner",
  },
  {
    path: "../images/hero/lifestyle-bnr.jpg",
    alt: "Lifestyle Banner",
  },
  {
    path: "../images/hero/gallery-bnr.jpg",
    alt: "Gallery Banner",
  },
  {
    path: "../images/hero/team-bnr.jpg",
    alt: "Team Banner",
  },
];
export const heroSM = [
  {
    path: "../images/hero/home-bnr-m.jpg",
    alt: "Home Banner",
  },
  {
    path: "../images/hero/residences-bnr-1-m.jpg",
    alt: "Residences Banner",
  },
  {
    path: "../images/hero/floorplan-bnr-m.jpg",
    alt: "Floorplan Banner",
  },
  {
    path: "../images/hero/lifestyle-bnr-m.jpg",
    alt: "Lifestyle Banner",
  },
  {
    path: "../images/hero/gallery-bnr-m.jpg",
    alt: "Gallery Banner",
  },
  {
    path: "../images/hero/team-bnr-m.jpg",
    alt: "Team Banner",
  },
];
export const heroMenu = [
  {
    path: "../images/hero/home-bnr-s.jpg",
    alt: "Home Banner",
  },
  {
    path: "../images/hero/residences-bnr-1-s.jpg",
    alt: "Residences Banner",
  },
  {
    path: "../images/hero/floorplan-bnr-s.jpg",
    alt: "Floorplan Banner",
  },
  {
    path: "../images/hero/lifestyle-bnr-1-s.jpg",
    alt: "Lifestyle Banner",
  },
  {
    path: "../images/hero/gallery-bnr-s.jpg",
    alt: "Gallery Banner",
  },
  {
    path: "../images/hero/team-bnr-s.jpg",
    alt: "Team Banner",
  },
];

export const floorplansThumbs = [
  {
    id: 1,
    name: "Plan A",
    path: "../images/floorplans/floorplan-thumb/SL_Web Floorplan_A.jpg",
    pathL: "../images/floorplansDetail/SL_Web-Floorplan_L_A.jpg",
    bed: 2,
    bath: "2.5",
    flex: "1",
    interior: "1,354",
    exterior: "131",
    sf: "1,485",
    link360: "https://vr.justeasy.cn/view/16uz6z4j5b531z13-1715058992.html",
  },
  {
    id: 2,
    name: "Plan B1",
    path: "../images/floorplans/floorplan-thumb/SL_Web Floorplan_B1.jpg",
    pathL: "../images/floorplansDetail/SL_Web-Floorplan_L_B1.jpg",
    bed: "3",
    bath: "2.5",
    flex: "",
    interior: "1,364",
    exterior: "166",
    sf: "1,530",
    link360: "https://vr.justeasy.cn/view/166p505g36z52nf5-1715059512.html",
  },
  {
    id: 3,
    name: "Plan B2",
    path: "../images/floorplans/floorplan-thumb/SL_Web Floorplan_B2.jpg",
    pathL: "../images/floorplansDetail/SL_Web-Floorplan_L_B2.jpg",
    bed: "3",
    bath: "2.5",
    flex: "",
    interior: "1,347",
    exterior: "166",
    sf: "1,513",
    link360: "https://vr.justeasy.cn/view/166p505g36z52nf5-1715059512.html",
  },
  // {
  //   id: 4,
  //   name: "Plan C (Coming Soon)",
  //   path: "../images/floorplans/floorplan-thumb/SL_Web Floorplan_C.jpg",
  //   pathL: "../images/floorplansDetail/SL_Web-Floorplan_L_C.jpg",
  //   bed: "4",
  //   bath: "3.5",
  //   flex: "",
  //   interior: "1,471",
  //   exterior: "203",
  //   sf: "1,674",
  //   link360: "https://vr.justeasy.cn/view/a1667784pd12s5q0-1715829500.html",
  // },
  {
    id: 4,
    name: "Plan D",
    path: "../images/floorplans/floorplan-thumb/SL_Web Floorplan_D.jpg",
    pathL: "../images/floorplansDetail/SL_Web-Floorplan_L_D.jpg",
    bed: "3",
    bath: "2.5",
    flex: "1",
    interior: "1,428",
    exterior: "175",
    sf: "1,603",
    link360: "https://vr.justeasy.cn/view/16677878501o3a18-1715317763.html",
  },
  {
    id: 5,
    name: "Plan E",
    path: "../images/floorplans/floorplan-thumb/SL_Web Floorplan_E.jpg",
    pathL: "../images/floorplansDetail/SL_Web-Floorplan_L_E.jpg",
    bed: "4",
    bath: "3",
    flex: "",
    interior: "1,655",
    exterior: "169",
    sf: "1,824",
    link360: "https://vr.justeasy.cn/view/cl3166685g748436-1715137387.html",
  },
  {
    id: 6,
    name: "Plan F",
    path: "../images/floorplans/floorplan-thumb/SL_Web Floorplan_F.jpg",
    pathL: "../images/floorplansDetail/SL_Web-Floorplan_L_F.jpg",
    bed: "4",
    bath: "4",
    flex: "",
    interior: "1,787",
    exterior: "408",
    sf: "2,195",
    link360: "https://vr.justeasy.cn/view/15a66t772860k880-1715317482.html",
  },
];
export const first3floorplansThumbs = floorplansThumbs.slice(0, 3);

export const smarterItems = [
  {
    path: "../images/interiorsFeatures/smartLiving/smartHome-1.jpg",
    heading: "Smart Switch",
    detail:
      "Enjoy the convenience of light control via voice command, remote control through mobile phone, and one button to turn on / off all the lights.",
  },
  {
    path: "../images/interiorsFeatures/smartLiving/smartHome-2.jpg",
    heading: "Central Hub",
    detail:
      "The central hub orchestrates your entire smart home system, connecting and controlling all your devices effortlessly.",
  },
  {
    path: "../images/interiorsFeatures/smartLiving/smartHome-3.jpg",
    heading: "Scene Control",
    detail:
      "Scene control simplifies home management by allowing users to activate preset combinations of smart device settings with a single command or tap.",
  },
  {
    path: "../images/interiorsFeatures/smartLiving/smartHome-4.jpg",
    heading: "Water Leakage Sensor",
    detail:
      "Get notification when a water leakage incident occurred and prevent costly repair.",
  },
  {
    path: "../images/interiorsFeatures/smartLiving/smartHome-5.jpg",
    heading: "Smart Mini Sensor",
    detail:
      "it enhances your smart home by providing instant alerts and triggering compatible light switches.",
  },
  {
    path: "../images/interiorsFeatures/smartLiving/smartHome-6.jpg",
    heading: "Environmental Monitor",
    detail:
      "Detects home environment changes in CO2, Temperature, Humidity, Noise, Brightness, PM 2.5 and Methanal levels.",
  },
  // {
  //   path: "../images/interiorsFeatures/smartLiving/smartHome-7.jpg",
  //   heading: "Smart Lock (Optional)",
  //   detail:
  //     "It is equipped with fingerprint and passcode functionality, ensuring secure and convenient entry into the home.",
  // },
];

export const locationMap = [
  {
    name: "map",
    path: "../images/locationLifestyle/map/Shannon Lynn_Map_default.svg",
  },
  {
    name: "Parks",
    path: "../images/locationLifestyle/map/Shannon Lynn_Map_parks.svg",
  },
  {
    name: "education",
    path: "../images/locationLifestyle/map/Shannon Lynn_Map-education.svg",
  },
  {
    name: "restaurants",
    path: "../images/locationLifestyle/map/Shannon Lynn_Map_restaurants.svg",
  },
  {
    name: "winery",
    path: "../images/locationLifestyle/map/Shannon Lynn_Map_winery.svg",
  },
  {
    name: "shoppings",
    path: "../images/locationLifestyle/map/Shannon Lynn_Map_shoppings.svg",
  },
];

export const theTeam = [
  {
    title: "Developer",
    company: "Landmark West Group",
    intro:
      "Landmark West Group has developed and serviced 30+ community-oriented projects in Alberta and British Columbia, spreading over 5,000 acres of land while contributing to each local community’s social footprint and urban development.",
    path: "../images/theTeam/Landmark-West-Logo.png",
    link: "https://www.landmarkgroups.ca/",
  },
  {
    title: "Developer",
    company: "Unik-Town Development",
    intro:
      "Working alongside renowned local industry leaders, UniK-Town Developments is committed to delivering high-quality lakeside living in West Kelowna. This exclusive golf community townhome project is designed to meet the needs of first-time buyers and those seeking an exceptional Okanagan lifestyle.",
    path: "../images/theTeam/unik town_logo_white-02.png",
    link: "https://www.lifestylemarketing.ca/",
  },
  {
    title: "Developer",
    company: "Injoy Development",
    intro:
      "Injoy Development Group Inc. (IDG) is a Vancouver-based real estate development company specializing in high-end condominiums tailored to the needs of young adults along the Pacific Coast.",
    path: "../images/theTeam/injoy-logo_white-06.png",
    link: "https://injoydevelopment.com/",
  },
  {
    title: "CONSTRUCTION",
    company: "Vanhome Construction",
    intro:
      "Vanhome Construction Ltd. is a construction company based in Vancouver with a focus on both commercial and single-house construction management. In the past ten years, Vanhome has invested and constructed many projects in BC, such as 10,000-square-foot mansions, condos, townhouses, senior apartments, etc.",
    path: "../images/theTeam/VANHOME LOGO-white-02.png",
    link: "https://vanhomeconstruction.com/",
  },
  {
    title: "Sales & Marketing",
    company: "Ace Project Marketing Group",
    intro:
      "Real estate is what matters to Ace, our developers, and the communities we serve. We are a team of constantly curious industry professionals, immersed in comparable market trends and outlook. Always exploring new technologies and strategies to realize best results for our clients, we understand that adaptability is key to leading the way in an ever-changing industry.",
    path: "../images/theTeam/ACE-logo-white-02.png",
    link: "https://amareal.ca/",
  },
  // {
  //   title: "Sales & Marketing",
  //   company: "Oakwyn Realty",
  //   intro:
  //     "Ace Project Marketing Group is proud to have a strong affiliate partnership with Oakwyn Realty. Since launching in 2013, Oakwyn Realty has experienced significant growth year-over-year and was named one of “Canada’s Top Growing Companies” in both 2020 and 2021 by The Globe and Mail.",
  //   path: "../images/theTeam/oakwyn-logo-black.png",
  //   link: "https://www.oakwyn.com/",
  // },
  {
    title: "Brand & visualization",
    company: "ALab Creative",
    intro:
      "ALab Creative is an integrated creative agency based in Vancouver. With over a decade of creative experience, ALab develops a diverse range of work including brand strategy and identity, art direction, print and digital design, website design, animation, 3D visualization, and 360 virtual tour.",
    path: "../images/theTeam/ALab_Logo-white.png",
    link: "https://www.alabcreative.com/",
  },
];

export const galleryDesktop = [
  {
    id: 0,
    title: "Architecture",
    imgs: [
      {
        id: 0,
        path: "../images/gallery/desktop/archituecture/gallery-1.jpg",
        pathL: "../images/gallery/desktop/archituecture/gallery-1.jpg",
      },
      {
        id: 1,
        path: "../images/gallery/desktop/archituecture/gallery-2.jpg",
        pathL: "../images/gallery/desktop-l/archituecture/gallery-2.jpg",
      },
      {
        id: 2,
        path: "../images/gallery/desktop/archituecture/gallery-3.jpg",
        pathL: "../images/gallery/desktop/archituecture/gallery-3.jpg",
      },
      {
        id: 3,
        path: "../images/gallery/desktop/archituecture/gallery-4.jpg",
        pathL: "../images/gallery/desktop-l/archituecture/gallery-4.jpg",
      },
      {
        id: 4,
        path: "../images/gallery/desktop/archituecture/gallery-5.jpg",
        pathL: "../images/gallery/desktop/archituecture/gallery-5.jpg",
      },
    ],
  },
  {
    id: 1,
    title: "Interiors / Plan A",
    imgs: [
      {
        id: 5,
        path: "../images/gallery/desktop/planA/gallery-6.jpg",
        pathL: "../images/gallery/desktop/planA/gallery-6.jpg",
      },
      {
        id: 6,
        path: "../images/gallery/desktop/planA/gallery-7.jpg",
        pathL: "../images/gallery/desktop-l/planA/gallery-7.jpg",
      },
      {
        id: 7,
        path: "../images/gallery/desktop/planA/gallery-8.jpg",
        pathL: "../images/gallery/desktop/planA/gallery-8.jpg",
      },
      {
        id: 8,
        path: "../images/gallery/desktop/planA/gallery-9.jpg",
        pathL: "../images/gallery/desktop/planA/gallery-9.jpg",
      },
      {
        id: 9,
        path: "../images/gallery/desktop/planA/gallery-10.jpg",
        pathL: "../images/gallery/desktop/planA/gallery-10.jpg",
      },
      {
        id: 10,
        path: "../images/gallery/desktop/planA/gallery-11.jpg",
        pathL: "../images/gallery/desktop/planA/gallery-11.jpg",
      },
    ],
  },
  {
    id: 2,
    title: "Interiors / Plan B2",
    imgs: [
      {
        id: 11,
        path: "../images/gallery/desktop/planB/gallery-12.jpg",
        pathL: "../images/gallery/desktop/planB/gallery-12.jpg",
      },
      {
        id: 12,
        path: "../images/gallery/desktop/planB/gallery-13.jpg",
        pathL: "../images/gallery/desktop-l/planB/gallery-13.jpg",
      },
      {
        id: 13,
        path: "../images/gallery/desktop/planB/gallery-14.jpg",
        pathL: "../images/gallery/desktop/planB/gallery-14.jpg",
      },
      {
        id: 14,
        path: "../images/gallery/desktop/planB/gallery-15.jpg",
        pathL: "../images/gallery/desktop/planB/gallery-15.jpg",
      },
      {
        id: 15,
        path: "../images/gallery/desktop/planB/gallery-16.jpg",
        pathL: "../images/gallery/desktop/planB/gallery-16.jpg",
      },
      {
        id: 16,
        path: "../images/gallery/desktop/planB/gallery-17.jpg",
        pathL: "../images/gallery/desktop/planB/gallery-17.jpg",
      },
    ],
  },
  // {
  //   id: 3,
  //   title: "Interiors / Plan C",
  //   imgs: [
  //     {
  //       id: 17,
  //       path: "../images/gallery/desktop/planC/gallery-18.jpg",
  //       pathL: "../images/gallery/desktop/planC/gallery-18.jpg",
  //     },
  //     {
  //       id: 18,
  //       path: "../images/gallery/desktop/planC/gallery-19.jpg",
  //       pathL: "../images/gallery/desktop-l/planC/gallery-19.jpg",
  //     },
  //     {
  //       id: 19,
  //       path: "../images/gallery/desktop/planC/gallery-20.jpg",
  //       pathL: "../images/gallery/desktop/planC/gallery-20.jpg",
  //     },
  //     {
  //       id: 20,
  //       path: "../images/gallery/desktop/planC/gallery-21.jpg",
  //       pathL: "../images/gallery/desktop/planC/gallery-21.jpg",
  //     },
  //     {
  //       id: 21,
  //       path: "../images/gallery/desktop/planC/gallery-22.jpg",
  //       pathL: "../images/gallery/desktop/planC/gallery-22.jpg",
  //     },
  //     {
  //       id: 22,
  //       path: "../images/gallery/desktop/planC/gallery-23.jpg",
  //       pathL: "../images/gallery/desktop/planC/gallery-23.jpg",
  //     },
  //     {
  //       id: 23,
  //       path: "../images/gallery/desktop/planC/gallery-24.jpg",
  //       pathL: "../images/gallery/desktop/planC/gallery-24.jpg",
  //     },
  //     {
  //       id: 24,
  //       path: "../images/gallery/desktop/planC/gallery-25.jpg",
  //       pathL: "../images/gallery/desktop/planC/gallery-25.jpg",
  //     },
  //   ],
  // },
  {
    id: 4,
    title: "Interiors / Plan D",
    imgs: [
      {
        id: 23,
        path: "../images/gallery/desktop/planD/gallery-24.jpg",
        pathL: "../images/gallery/desktop/planD/gallery-24.jpg",
      },
      {
        id: 24,
        path: "../images/gallery/desktop/planD/gallery-25.jpg",
        pathL: "../images/gallery/desktop/planD/gallery-25.jpg",
      },
      {
        id: 25,
        path: "../images/gallery/desktop/planD/gallery-26.jpg",
        pathL: "../images/gallery/desktop/planD/gallery-26.jpg",
      },
      {
        id: 26,
        path: "../images/gallery/desktop/planD/gallery-27.jpg",
        pathL: "../images/gallery/desktop/planD/gallery-27.jpg",
      },
      {
        id: 27,
        path: "../images/gallery/desktop/planD/gallery-28.jpg",
        pathL: "../images/gallery/desktop/planD/gallery-28.jpg",
      },
      {
        id: 28,
        path: "../images/gallery/desktop/planD/gallery-29.jpg",
        pathL: "../images/gallery/desktop/planD/gallery-29.jpg",
      },
      {
        id: 29,
        path: "../images/gallery/desktop/planD/gallery-30.jpg",
        pathL: "../images/gallery/desktop/planD/gallery-30.jpg",
      },
    ],
  },
  {
    id: 5,
    title: "Interiors / Plan E",
    imgs: [
      {
        id: 30,
        path: "../images/gallery/desktop/planE/gallery-31.jpg",
        pathL: "../images/gallery/desktop/planE/gallery-31.jpg",
      },
      {
        id: 31,
        path: "../images/gallery/desktop/planE/gallery-32.jpg",
        pathL: "../images/gallery/desktop/planE/gallery-32.jpg",
      },
      {
        id: 32,
        path: "../images/gallery/desktop/planE/gallery-33.jpg",
        pathL: "../images/gallery/desktop/planE/gallery-33.jpg",
      },
      {
        id: 33,
        path: "../images/gallery/desktop/planE/gallery-34.jpg",
        pathL: "../images/gallery/desktop/planE/gallery-34.jpg",
      },
      {
        id: 34,
        path: "../images/gallery/desktop/planE/gallery-35.jpg",
        pathL: "../images/gallery/desktop/planE/gallery-35.jpg",
      },
      {
        id: 35,
        path: "../images/gallery/desktop/planE/gallery-36.jpg",
        pathL: "../images/gallery/desktop/planE/gallery-36.jpg",
      },
      {
        id: 36,
        path: "../images/gallery/desktop/planE/gallery-37.jpg",
        pathL: "../images/gallery/desktop/planE/gallery-37.jpg",
      },
      {
        id: 37,
        path: "../images/gallery/desktop/planE/gallery-38.jpg",
        pathL: "../images/gallery/desktop/planE/gallery-38.jpg",
      },
      {
        id: 38,
        path: "../images/gallery/desktop/planE/gallery-39.jpg",
        pathL: "../images/gallery/desktop/planE/gallery-39.jpg",
      },
      {
        id: 39,
        path: "../images/gallery/desktop/planE/gallery-40.jpg",
        pathL: "../images/gallery/desktop/planE/gallery-40.jpg",
      },
    ],
  },
  {
    id: 6,
    title: "Interiors / Plan F",
    imgs: [
      {
        id: 40,
        path: "../images/gallery/desktop/planF/gallery-41.jpg",
        pathL: "../images/gallery/desktop/planF/gallery-41.jpg",
      },
      {
        id: 41,
        path: "../images/gallery/desktop/planF/gallery-42.jpg",
        pathL: "../images/gallery/desktop-l/planF/gallery-42.jpg",
      },
      {
        id: 42,
        path: "../images/gallery/desktop/planF/gallery-43.jpg",
        pathL: "../images/gallery/desktop/planF/gallery-43.jpg",
      },
      {
        id: 43,
        path: "../images/gallery/desktop/planF/gallery-44.jpg",
        pathL: "../images/gallery/desktop/planF/gallery-44.jpg",
      },
      {
        id: 44,
        path: "../images/gallery/desktop/planF/gallery-45.jpg",
        pathL: "../images/gallery/desktop/planF/gallery-45.jpg",
      },
      {
        id: 45,
        path: "../images/gallery/desktop/planF/gallery-46.jpg",
        pathL: "../images/gallery/desktop/planF/gallery-46.jpg",
      },
      {
        id: 46,
        path: "../images/gallery/desktop/planF/gallery-47.jpg",
        pathL: "../images/gallery/desktop/planF/gallery-47.jpg",
      },
      {
        id: 47,
        path: "../images/gallery/desktop/planF/gallery-48.jpg",
        pathL: "../images/gallery/desktop/planF/gallery-48.jpg",
      },
    ],
  },
];
export const galleryMobile = [
  {
    title: "Architecture",
    imgs: [
      {
        id: 1,
        path: "../images/gallery/mobile/archituecture-m/gallery-m-1.jpg",
      },
      {
        id: 2,
        path: "../images/gallery/mobile/archituecture-m/gallery-m-2.jpg",
      },
      {
        id: 3,
        path: "../images/gallery/mobile/archituecture-m/gallery-m-3.jpg",
      },
      {
        id: 4,
        path: "../images/gallery/mobile/archituecture-m/gallery-m-4.jpg",
      },
      {
        id: 5,
        path: "../images/gallery/mobile/archituecture-m/gallery-m-5.jpg",
      },
    ],
  },
  {
    title: "Interiors / Plan A",
    imgs: [
      { id: 6, path: "../images/gallery/mobile/planA-m/gallery-m-6.jpg" },
      { id: 7, path: "../images/gallery/mobile/planA-m/gallery-m-7.jpg" },
      { id: 8, path: "../images/gallery/mobile/planA-m/gallery-m-8.jpg" },
      { id: 9, path: "../images/gallery/mobile/planA-m/gallery-m-9.jpg" },
      { id: 10, path: "../images/gallery/mobile/planA-m/gallery-m-10.jpg" },
      { id: 11, path: "../images/gallery/mobile/planA-m/gallery-m-11.jpg" },
    ],
  },
  {
    title: "Interiors / Plan B2",
    imgs: [
      { id: 12, path: "../images/gallery/mobile/planB-m/gallery-m-12.jpg" },
      { id: 13, path: "../images/gallery/mobile/planB-m/gallery-m-13.jpg" },
      { id: 14, path: "../images/gallery/mobile/planB-m/gallery-m-14.jpg" },
      { id: 15, path: "../images/gallery/mobile/planB-m/gallery-m-15.jpg" },
      { id: 16, path: "../images/gallery/mobile/planB-m/gallery-m-16.jpg" },
      { id: 17, path: "../images/gallery/mobile/planB-m/gallery-m-17.jpg" },
    ],
  },
  // {
  //   title: "Interiors / Plan C",
  //   imgs: [
  //     { id: 18, path: "../images/gallery/mobile/planC-m/gallery-m-18.jpg" },
  //     { id: 19, path: "../images/gallery/mobile/planC-m/gallery-m-19.jpg" },
  //     { id: 20, path: "../images/gallery/mobile/planC-m/gallery-m-20.jpg" },
  //     { id: 21, path: "../images/gallery/mobile/planC-m/gallery-m-21.jpg" },
  //     { id: 22, path: "../images/gallery/mobile/planC-m/gallery-m-22.jpg" },
  //     { id: 23, path: "../images/gallery/mobile/planC-m/gallery-m-23.jpg" },
  //   ],
  // },
  {
    title: "Interiors / Plan D",
    imgs: [
      { id: 24, path: "../images/gallery/mobile/planD-m/gallery-m-24.jpg" },
      { id: 25, path: "../images/gallery/mobile/planD-m/gallery-m-25.jpg" },
      { id: 26, path: "../images/gallery/mobile/planD-m/gallery-m-26.jpg" },
      { id: 27, path: "../images/gallery/mobile/planD-m/gallery-m-27.jpg" },
      { id: 28, path: "../images/gallery/mobile/planD-m/gallery-m-28.jpg" },
      { id: 29, path: "../images/gallery/mobile/planD-m/gallery-m-29.jpg" },
      { id: 30, path: "../images/gallery/mobile/planD-m/gallery-m-30.jpg" },
    ],
  },
  {
    title: "Interiors / Plan E",
    imgs: [
      { id: 31, path: "../images/gallery/mobile/planE-m/gallery-m-31.jpg" },
      { id: 32, path: "../images/gallery/mobile/planE-m/gallery-m-32.jpg" },
      { id: 33, path: "../images/gallery/mobile/planE-m/gallery-m-33.jpg" },
      { id: 34, path: "../images/gallery/mobile/planE-m/gallery-m-34.jpg" },
      { id: 35, path: "../images/gallery/mobile/planE-m/gallery-m-35.jpg" },
      { id: 36, path: "../images/gallery/mobile/planE-m/gallery-m-36.jpg" },
      { id: 37, path: "../images/gallery/mobile/planE-m/gallery-m-37.jpg" },
      { id: 38, path: "../images/gallery/mobile/planE-m/gallery-m-38.jpg" },
      { id: 39, path: "../images/gallery/mobile/planE-m/gallery-m-39.jpg" },
      { id: 40, path: "../images/gallery/mobile/planE-m/gallery-m-40.jpg" },
    ],
  },
  {
    title: "Interiors / Plan F",
    imgs: [
      { id: 41, path: "../images/gallery/mobile/planF-m/gallery-m-41.jpg" },
      { id: 42, path: "../images/gallery/mobile/planF-m/gallery-m-42.jpg" },
      { id: 43, path: "../images/gallery/mobile/planF-m/gallery-m-43.jpg" },
      { id: 44, path: "../images/gallery/mobile/planF-m/gallery-m-44.jpg" },
      { id: 45, path: "../images/gallery/mobile/planF-m/gallery-m-45.jpg" },
      { id: 46, path: "../images/gallery/mobile/planF-m/gallery-m-46.jpg" },
      { id: 47, path: "../images/gallery/mobile/planF-m/gallery-m-47.jpg" },
      { id: 48, path: "../images/gallery/mobile/planF-m/gallery-m-48.jpg" },
    ],
  },
];
